import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/core';
import Container from 'react-bootstrap/Container';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const content = css`
  min-height: ${contentHeight}vh;
  margin: 0 auto;
  max-width: 65ch;
`;

const subHeadingStyles = css`
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const anchorStyles = css`
  font-weight: 500;
  text-decoration: underline;
  color: #000000;

  &:hover {
    color: #000000;
  }
`;

const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric'
});

const Article = ({ data: { prismicArticle } }) => {
  const { data } = prismicArticle;
  const date = new Date(data.date);
  const formattedDate = dateTimeFormat.format(date);

  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h2>{data.title.text}</h2>
        <div css={subHeadingStyles}>
          <time>{formattedDate}</time>
          <Link css={anchorStyles} to="/articles/">
            Back
          </Link>
        </div>
        <article dangerouslySetInnerHTML={{
          __html: prismicArticle.data.content.html,
        }}/>
      </Container>
    </Layout>
  );
};

export default Article;

export const pageQuery = graphql`
  query ArticleBySlug($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        date
        content {
          html
        }
      }
    }
  }
`;
